<template>
	<div class="product">
		<van-form class="form" @submit="onSubmit">
			<!-- 商品分类-->
			<van-field readonly clickable required name="picker" v-model="value2" label="商品分类" placeholder="点击选择商品分类"
				@click="showPicker = true" :rules="[{ required: true, message: '请填写商品分类' }]" />
			<van-popup v-model:show="showPicker" position="bottom">
				<van-picker :default-index="pickerDefault" show-toolbar value-key="label" :columns="columns"
					@confirm="onConfirm" @cancel="showPicker = false" />
			</van-popup>
			<!-- 商品名称-->
			<van-field required v-model="formdata.name" name="商品名称" label="商品名称" placeholder="请填写商品名称"
				:rules="[{ required: true, message: '请填写商品名称' }]" />
			<!-- 商品类型-->
			<!-- <van-field readonly required clickable name="picker" v-model="value1" label="商品类型" placeholder="点击选择商品类型"
				@click="showPicke = true" :rules="[{ required: true, message: '请填写商品类型' }]" />
			<van-popup v-model:show="showPicke" position="bottom">
				<van-picker :default-index="pickerDefault2" show-toolbar value-key="label" :columns="column"
					@confirm="onConfirms" @cancel="showPicke = false" />
			</van-popup> -->
			<!-- 商品图片-->
			<van-field name="uploader" required label="商品主图" :rules="[{ required: true, message: '请选择商品主图' }]">
				<template #input>
					<van-uploader :max-count="1" name="image" :before-delete="afterDelete" :after-read="afterRead"
						:max-size="2048 * 1024" @oversize="onOversize" v-model="image" accept="image/*" />
				</template>
			</van-field>
			<!-- 组图 -->
			<van-field name="uploader" label="组图">
				<template #input>
					<van-uploader :before-delete="afterDelete" :after-read="afterRead" name="images" multiple
						:max-size="2048 * 1024" @oversize="onOversize" :max-count="4" v-model="images" />
				</template>
			</van-field>
			<!-- 价格-->
			<van-field v-model="formdata.price" name="价格" required label="价格" type="number" placeholder="请填写价格"
				:rules="[{ required: true, message: '请填写价格' }]" />
			<!-- 库存-->
			<van-field v-model="formdata.stock" name="库存" label="库存" type="number" placeholder="请填写库存" />
			<!-- 规格设置-->
			<van-field v-model="attrs" readonly @click="setHandle('attrs')" label="规格" />
			<!-- 单位-->
			<van-field v-model="formdata.unit" name="单位" label="单位" placeholder="请填写单位" />
			<!-- 简介-->
			<van-field v-model="formdata.intro" name="简介" type="textarea" label="简介" placeholder="请填写简介" />
			<!-- 详情-->
			<van-field label="商品详情" v-model="detail" readonly @click="setHandle('detail')" />
			<!-- 购买须知-->
			<van-field v-model="buy_detail" readonly label="购买须知" placeholder="请填写购买须知"
				@click="setHandle('buy_detail')" />
			<van-field name="switch" label="状态" required>
				<template #input>
					<van-switch v-model="checked" size="20" />
				</template>
			</van-field>
			<!-- <van-field name="is_appointment" label="预约开关" required>
				<template #input>
					<van-switch v-model="checkeds" size="20" />
				</template>
			</van-field> -->
			<div style="margin: 16px;">
				<van-button round block type="primary" native-type="submit">保存</van-button>
			</div>
		</van-form>
		<van-popup v-model:show="show" position="bottom" :style="{ height: '80%' }">
			<!--      <editor v-show="model==='detail'" name="detail" v-model="formdata.detail"/>-->
			<div v-show="model==='attrs'">
				<div class="attrs-title"><span>规格设置</span></div>

				<div>
					<van-field v-model="guige" label="规格" placeholder="请输入规格名" />
					<van-field v-model="guigeVal" label="规格值" placeholder="请输入规格值" />
				</div>
				<div style="float: right;margin:1rem;">
					<van-button size="small" type="primary" @click="queDing" style="margin:.3rem;border-radius: 5px;">确定
					</van-button>
				</div>
				<div v-if="showcols" style="margin:5rem .8rem 1rem .8rem;font-size:.9rem;color:##646566;">
					<div v-for='(item,index) in colorName' :key="index">
						<span>{{colorName[index]}}</span>
						<van-icon @click="showcol" name="close" style="margin-left:.2rem;" color="#1989fa" />
						<br>
						<div v-for='(item,index) in itemcount' :key="index"
							style="float: left;pindding:1rem;margin:.8rem;border-radius: 5px;">
							<van-tag v-if="showTap" closeable size="large" plain type="primary" @close="close(index)">
								{{itemcount[index]}}
							</van-tag>
						</div>
						<van-field v-model="sms" center clearable placeholder="请输入属性名称">
							<template #button>
								<van-button @click='addtab(sms)' style="border-radius: 5px;" size="small"
									type="primary">添加</van-button>
							</template>
						</van-field>
					</div>
					<div style="text-align:center">
						<van-button style="margin-top:.9rem" type="primary" size="small" @click="addAttr">立刻生成
						</van-button>
					</div>
					<van-row v-for="(item, index) in attrsData" :key="index" class="attrs">
						<van-col span="7">
							<van-field required v-model="attrsData[index]['sku']" placeholder="规格名称"
								:rules="[{ required: true, message: '请填写规格名称' }]" />
						</van-col>
						<van-col span="7">
							<van-field required v-model="attrsData[index]['price']" type="number" placeholder="商品价格"
								:rules="[{ required: true, message: '请填写商品价格' }]" />
						</van-col>
						<van-col span="7">
							<van-field required v-model="attrsData[index]['stock']" type="number" placeholder="库存数量"
								:rules="[{ required: true, message: '请填写库存数量' }]" />
						</van-col>
						<van-col span="3">
							<van-icon name="close" style="font-size: 18px;padding-top: 12px;" @click="delAttr(index)" />
						</van-col>
					</van-row>
				</div>
				<div style="margin: 16px;">
					<van-button round block type="primary" @click="submitAttrs">保存</van-button>
				</div>
			</div>
			<div v-show="model==='detail'">
				<Editor @changeNew="changeA"></Editor>
				<!-- <div style="margin: 16px;">
					<van-button round block type="primary" @click="submitAttrss">保存</van-button>
				</div> -->
			</div>
			<div v-show="model==='buy_detail'">
				<buyEditor @changeBuy="changeB"></buyEditor>
				<!-- <div style="margin: 16px;">
					<van-button round block type="primary" @click="submitAttrs">保存</van-button>
				</div> -->
			</div>
		</van-popup>
	</div>
</template>

<script>
	import Editor from "./Editor.vue"
	import buyEditor from './buyEditor.vue'
	import {
		cateOption,
		productAdd,
		productEdit,
		productInfo
	} from "@/api/product"
	import {
		uploadImage
	} from "@/api/common"
	import {
		Toast
	} from 'vant'
	import {
		ref
	} from 'vue';
	import
	http
	from '../../utils/config.js'
	export default {
		name: "ProductAdd",
		// components: { editor },
		setup() {
			const date = ref('');
			const showp = ref(false);
			const formatDate = (date) => `${date.getMonth() + 1}/${date.getDate()}`;
			const onConfirmp = (values) => {
				const [start, end] = values;
				showp.value = false;
				date.value = `${formatDate(start)} - ${formatDate(end)}`;
			}
			const onOversize = (file) => {
				console.log(file);
				Toast('文件大小不能超过 2M');
			};
			return {
				date,
				showp,
				onConfirmp,
				onOversize
			}
		},
		components: {
			Editor,
			buyEditor
		},
		data() {
			return {
				content: '',
				formdata: {
					cid: null,
					name: '',
					type: null,
					image: [],
					price: null,
					stock: null,
					sales: null,
					unit: null,
					intro: null,
					images: [],
					detail: null,
					buy_detail: null,
					attrs: {},
					status: 1,
					is_appointment: 1,
				},
				imageUrl: http.imageUrl,
				uploads: [],
				image: [],
				images: [],
				itemcount: [],
				colorName: [],
				checked: true,
				checkeds: false,
				showcols: false,
				pickerDefault: 0,
				pickerDefault2: 0,
				value2: '',
				showTap: true,
				// value1: '',
				guigeVal: '',
				guige: '',
				columns: [],
				column: [{
						value: 1,
						label: "团购商品"
					},
					{
						value: 2,
						label: "外卖商品"
					},
					{
						value: 4,
						label: "普通商品"
					}
				],
				showPicker: false,
				showPicke: false,
				show: false,
				model: 'attrs',
				attrs: '规格设置',
				detail: '编辑商品详情',
				buy_detail: '编辑购买须知',
				attrsValue: '',
				attrsData: [],
				specification: [],
				con: '',
				buyCon: '',
				isSubmit: true,
				bigArr: {},
				sptionIn: '',
				sptionVal: '',
				attrss: {
					"attr": [],
					"value": []
				},
				sption: {},
				fArrtsData: '',
				abc: [],
				sms:''
			}
		},
		mounted() {
			this.getOptions()
			if (this.$route.query.id) {
				this.initData() 
				this.guigeSetting()
			}
		},

		methods: {
			// shopDetail(){
			// 	this.$router.push('/product/ChildOne')
			// },
			changeA(event) {
				this.con = event
				this.show = false
				this.formdata.detail = this.con
				// console.log(this.con)
				// for(var i = 0; i < this.con.length;i++){
				// 	console.log(this.con[i].children)
				// }
			},
			changeB(event) {
				this.buyCon = event
				this.show = false
				this.formdata.buy_detail = this.buyCon
			},
			initData() {
				productInfo({
					id: this.$route.query.id
				}).then((res) => {
					console.log(res)
					this.formdata = res.data
					this.value2 = this.formdata.cname
					// this.value1 = this.formdata.cname
					if(res.data.attrs == null){
						console.log(res.data.attrs)
					}else{
						this.guige = res.data.attrs.attr[0].attr_name
						this.attrsData = res.data.attrs.value
					}
					console.log(this.guige)
					console.log(this.attrsData)
					if (res.data.status === 0) {
						this.checked = false
					} else {
						this.checked = true
					}
					this.attrss = JSON.stringify(this.attrsData)
					// console.log(this.attrss )
					
					if (res.data.image) {
						this.image = [{
							url: 'https://shop2.esw235.cn/' + res.data.image
						}]
					}
					if (this.formdata.images) {
						// console.log(this.formdata.images)
						// this.uploads = res.data.images.split(";")
						for (const i in this.formdata.images) {
							this.images.push({
								url: 'https://shop2.esw235.cn/' + this.formdata.images[i]
							})
						}
					}

					this.column.map(v => {
						switch (v.value) {
							case this.formdata.type:
								this.onConfirms(v)
						}
					})
				})
			},
			close(val) {
				this.itemcount.splice(val,1);
				// this.val = false;
			},
			showcol() {
				this.showcols = false;
			},
			addtab(val) {
				this.itemcount.push(val)
				this.sms = ""
			},
			guigeSetting() {
				this.showcols = true;
			},
			queDing() {
				if (this.isSubmit) {
					this.isSubmit = false;
					if(!this.guige){
						return
						Toast.success('请添加规格')
					}
					if(!this.guigeVal){
						return
						Toast.success('请添加规格值')
					}
					this.colorName.push(this.guige)
					this.itemcount[0] = this.guigeVal
					for (let i = 0; i < this.colorName.length; i++) {
						this.sptionIn = this.colorName[i]
					}
					for (let i = 0; i < this.itemcount.length; i++) {
						this.sptionVal = this.itemcount[i]
					}
					// if(this.colorName !=='' && this.itemcount[0] !== ''){
					this.showcols = true
					// }
				} else {
					Toast.success('只能点击一次')
				}

			},
			onConfirm(value) {
				this.value2 = value.label;
				this.formdata.cid = value.value;
				this.showPicker = false;
			},
			onConfirms(value) {
				// this.value1 = value.label;
				this.formdata.type = value.value;
				this.showPicke = false;
				this.pickerDefault2 = value.value - 1
			},
			afterDelete(file, detail) {
				switch (detail.name) {
					case 'image':
						this.formdata.image = ''
						this.image.splice(detail.index, 1)
						break
					case 'images':
						this.uploads.splice(detail.index, 1)
						this.images.splice(detail.index, 1)
						break
				}
			},
			afterRead(file, detail) {
				const formData = new FormData();
				formData.append('file', file.file)
				uploadImage(formData).then((res) => {
					// console.log(res)
					if (detail.name === 'image') {
						this.formdata.image = res.data.path
					} else {
						this.uploads.push(res.data.path)
					}
				})
			},
			getOptions() {
				cateOption().then((res) => {
					// console.log(res)
					this.columns = res.data
					for (let i = 0; i < this.columns.length; i++) {
						if (this.columns[i]['value'] === this.formdata.cid)
							this.pickerDefault = i
					}
				})
			},
			onSubmit() {
				this.formdata.images = this.uploads.toString()
				if (this.$route.query.id) {
					productEdit(this.formdata).then((res) => {
						Toast.success('修改成功')
						this.$router.go(-1)
					})
				} else {
					productAdd(this.formdata).then((res) => {
						Toast.success('添加成功')
						// this.$router.go(-1)
						this.$router.push({
							path: '/product/list',
						})
					})
				}
			},
			setHandle(type) {
				this.model = type
				switch (type) {
					case 'attrs':
						this.show = true
						break;
					case 'detail':
						this.show = true
						break;
					case 'buy_detail':
						this.show = true
						break;
				}
			},
			addAttr() {
				for (let i = 0; i < this.itemcount.length; i++) {
					this.attrsData.push({
						sku: this.itemcount[i],
						price: '',
						stock: ''
					})
				}
				this.itemcount = []
				this.sms = ""
			},
			delAttr(index) {
				this.attrsData.splice(index, 1)
			},
			submitAttrs() {
				for (let j = 0; j < this.attrsData.length; j++) {
					this.abc.push(this.attrsData[j].sku)
				}
				this.specification.push({
					attr_name: this.sptionIn,
					attr_value: this.abc.toString()
				})
				this.attrss = this.attrsData
				this.sption = this.specification
				this.bigArr.attr = this.sption
				this.bigArr.value = this.attrss
				this.show = false
				this.formdata.attrs = this.bigArr
			},
		},
		watch: {
			checked: {
				handler: function(v) {
					if (v) {
						this.formdata.status = 1
					} else {
						this.formdata.status = 0
					}
				},
				immediate: true
			},
			checkeds: {
				handler: function(v) {
					if (v) {
						this.formdata.is_appointment = 1
					} else {
						this.formdata.is_appointment = 0
					}
				},
				immediate: true
			}
		}
	}
</script>

<style scoped lang="less">
	.product {
		min-height: calc(100vh);

		.form {
			padding: 20px 20px 60px 20px;
			background-color: white;
		}

		.attrs {
			padding: 0 10px;
		}

		.attrs-title {

			padding: 10px;
		}

		.attrs-add {
			float: right;
		}

		.productsIn {
			display: flex;
			align-items: center;
			margin-top: 15px;
			height: 120px;
			margin-left: 15px;

			p {
				font-size: 14px;
			}

			textarea {
				margin-left: 5px;
				width: 70%;
				height: 100px;
				resize: none;
				padding: 5px;
			}
		}

		.buyDetail {
			// width: 80%;
			// margin: 0 auto;
			margin-top: 15px;
			// border-bottom: solid 1px #969799;
		}

		.rule {
			display: flex;
			align-items: center;
			height: 120px;
			margin-left: 15px;
			margin-top: 15px;

			p {
				color: #646566;
				font-size: 14px;
			}

			textarea {
				margin-left: 5px;
				width: 70%;
				height: 100px;
				resize: none;
				padding: 5px;
				border: solid 1px #ccc;
			}
		}
	}
</style>
