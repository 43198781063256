<template>
	<div class="buyEditor">
		<div id="div2"></div>
		<button @click="tj" class="save">保存</button>
	</div>
</template>

<script>
	import E from 'wangeditor'
	import {
		uploadImg
	} from "@/api/index";
	export default {
		name: '',
		props: {
			id: String
		},
		data() {
			return {
				editorContent: ''
			};
		},
		methods: {
			tj(){
				// console.log(this.editorContent)
				this.$emit('changeBuy',this.editorContent)
			}
		},
		mounted() {
			const editor = new E('#div2')
			editor.config.debug = true;
			editor.config.uploadFileName = 'file'
			// editor.config.uploadImgServer = this.image; //设置上传文件的服务器路径
			editor.config.emotions = []
			editor.config.uploadImgMaxSize = 2 * 1024 * 1024 // 2M
			editor.config.uploadImgMaxLength = 5;
			editor.config.fontSizes = {
			    'x-small': { name: '10px', value: '1' },
			    'small': { name: '13px', value: '2' },
			    'normal': { name: '16px', value: '3' },
			    'large': { name: '18px', value: '4' },
			    'x-large': { name: '24px', value: '5' },
			    'xx-large': { name: '32px', value: '6' },
			    'xxx-large': { name: '48px', value: '7' },
			}
			editor.config.customUploadImg = function(file, insert){
				var formData = new FormData();
				// console.log(file)
				formData.append('file', file[0])
				uploadImg(formData).then((res) => {
					console.log(res)
					// this.image = res.data.path
					insert('https://shop2.esw235.cn/'+res.data.path)
				})
			}
			editor.config.uploadImgHooks = {
				success: function(xhr, editor, result) {
					// 图片上传并返回结果，图片插入成功之后触发
					console.log(result)
				},
				fail: function(xhr, editor, result) {
					// 图片上传并返回结果，但图片插入错误时触发
				},
				error: function(xhr, editor) {
					// 图片上传出错时触发
				}
				
			}
			editor.config.onchange = (html) => {
			   this.editorContent = editor.txt.html()
			  }
			editor.create()
		}
	}
</script>

<style lang="less" scoped>
	.wrapper {
		width: 100%;
		display: flex;
		flex-direction: column;
		box-sizing: border-box;
	}
	.buyEditor{
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		.save{
			width: 80%;
			height: 40px;
			color: #fff;
			background-color: #1989fa;
			border: 1px solid #1989fa;
			border-radius: 20px;
			margin: 15px 0;
		}
	}
</style>
